/* eslint-disable arrow-body-style */
import { ref, computed, watch } from '@vue/composition-api'
import {
  getProductsByFacility,
  getTerminalFacilityActive,
  getReservationPos,
  addGuest,
  createInvoicePos,
  createInvoicePosDeclined,
  getInvoiceOpenTabs,
  deleteInvoiceOpenTab,
  updateInvoicePos,
} from '@api'
import { useUtils } from '@core/libs/i18n'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import {
  mdiCartCheck,
  mdiCreditCardOutline,
  mdiCheck,
} from '@mdi/js'
import { success, error } from '@/@core/utils/toasted'

export default function useCheckout() {
  const { t } = useUtils()
  const { userData, encrypt } = useCryptoJs()
  const { configFacility } = useSelectOptions()
  const { hasPermission } = usePermissions()

  const searchQuery = ref('')
  const loading = ref(false)
  const flag = ref(true)
  const timerSearch = ref(null)

  const currentStep = ref(0)
  const cartItems = ref([])
  const checkouts = ref([])
  const checkoutsAll = ref([])

  const fields = ref([])
  const terminals = ref([])
  const selectedTerminal = ref(null)

  const checkoutSteps = ref([
    {
      title: t('products.cart'),
      icon: mdiCartCheck,
      size: 25,
      tab: 0,
      disabled: false,
    },
    {
      title: t('products.payment'),
      icon: mdiCreditCardOutline,
      size: 25,
      tab: 1,
      disabled: true,
    },
    {
      title: t('products.confirmation'),
      icon: mdiCheck,
      size: 25,
      tab: 2,
      disabled: true,
    },
  ])
  const computedCheckoutSteps = computed(() => checkoutSteps.value)

  const paymentData = ref({
    payment_method_id: null,
    terminal_id: selectedTerminal.value,
    facility_id: configFacility.value,
    user_console_id: userData.value.username,

    amount: 0,
    guest_name: null,
    email: userData.value.email || null,
    phone_number: userData.value.phone_number || null,
    user_id: null,

    info: {
      payment_method_name: null,
      observation: null,
      invoce_amount: null,
      client_amount: null,
      change: null,
    },
  })

  const checkoutDataInit = ref({
    invoice_id: null,
    facility_id: configFacility.value,
    reservation_name: null,
    reservation_id: null,
    user_id: null,
    user_console_id: userData.value.username,
    fullname: null,
    guest_name: null,
    payment_info: null,
    email: null,
    phone_number: null,
    products: [],
    subtotal: 0,
    discount: 0,
    tax: 0,
    tip: 0,
    amount: 0,
    is_open_tab: null,
  })
  const checkoutData = ref(checkoutDataInit.value)
  const computedCartItems = computed(() => {
    if (!!checkoutData.value.reservation_id && !checkoutData.value.user_id && !checkoutData.value.guest_name) return cartItems.value.filter(e => e.visibility === 'R' || e.visibility === 'B')

    return cartItems.value.filter(e => e.visibility !== 'R')
  })

  const mapProducts = items => {
    let mapItems = []

    if (items.length) {
      mapItems = items.map(e => {
        return {
          id: e.id,
          name: e.name,
          description: e.description,
          status_str: e.status_str,
          status: e.status,
          image: e.image,
          visibility: e.visibility,
          editable: e.editable ? e.editable : true,

          unit_price: e.price,
          tax_percent: e.tax,

          quantity: 0,
          subtotal: 0,
          discount: 0,
          tax: 0,
          amount: 0,
        }
      })
    }

    return mapItems
  }

  // fetch data
  const fetchProducts = async () => {
    loading.value = true
    const resp = await getProductsByFacility(configFacility.value)
    cartItems.value = mapProducts(resp.data)
    loading.value = false
  }

  const fetchReservations = async () => {
    try {
      const resp = await getInvoiceOpenTabs(configFacility.value || 0)
      checkoutsAll.value = resp.data
      checkouts.value = resp.data
    } catch (e) {
      console.error(e)
      checkoutsAll.value = []
      checkouts.value = []
    }
  }

  const fetchFields = async () => {
    try {
      const resp = await getReservationPos(configFacility.value)
      fields.value = resp.data
    } catch (e) {
      console.error(e)
      fields.value = []
    }
  }

  const fetchTerminals = async () => {
    try {
      const resp = await getTerminalFacilityActive(configFacility.value)
      terminals.value = resp.data
      if (terminals.value.length === 1) {
        selectedTerminal.value = terminals.value[0].id
        paymentData.value.terminal_id = selectedTerminal.value
      }
    } catch (e) {
      console.error(e)
      terminals.value = []
    }
  }

  const fetchAddGuest = async item => {
    try {
      const body = {
        guest_name: item.name,
        guest_phone_number: item.phone,
        match_id: item.match,
      }
      await addGuest(body)

      await fetchFields()
    } catch (e) {
      console.error(e)
    }
  }

  const payInvoice = async (openTabInvoice = false, declined = false) => {
    let body = {}
    let resp = null
    const encryptInfo = encrypt(paymentData.value)
    if (!declined) {
      body = checkoutData.value
      body.amount = Math.round(checkoutData.value.amount * 100) / 100
      body.tax = Math.round(checkoutData.value.tax * 100) / 100
      body.payment_info = encryptInfo

      if (body.invoice_id) {
        resp = await updateInvoicePos(body, body.invoice_id)
      } else {
        resp = await createInvoicePos(body)
      }
    } else {
      body.payment_info = encryptInfo
      resp = await createInvoicePosDeclined(body)
    }

    if (resp.ok) {
      success(resp.message)
      if (!openTabInvoice) currentStep.value += 1
    } else error(resp.message)
  }

  const deleteInvoiceOpen = async id => {
    const resp = await deleteInvoiceOpenTab(id)
    if (resp.ok) {
      success(resp.message)
      await fetchReservations()
    } else error(resp.message)
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchProducts()
    }, 1000)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    await getDataBySearch()
  })

  watch([configFacility], async () => {
    await fetchProducts()
  })

  return {
    userData,
    cartItems,
    computedCartItems,
    checkouts,
    checkoutsAll,

    currentStep,
    checkoutSteps,
    computedCheckoutSteps,
    checkoutDataInit,
    checkoutData,
    fields,
    terminals,
    selectedTerminal,
    paymentData,

    configFacility,
    loading,
    searchQuery,

    t,

    fetchProducts,
    fetchReservations,
    fetchFields,
    fetchTerminals,
    fetchAddGuest,
    payInvoice,
    deleteInvoiceOpen,
    hasPermission,
  }
}
