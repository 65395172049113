<template>
  <v-slide-group
    v-model="computedCurrentStep"
    class="app-stepper"
    show-arrows
    :direction="direction"
    :class="`app-stepper-${align}`"
  >
    <v-slide-item
      v-for="(item, index) in items"
      :key="item.title"
      :value="index"
    >
      <div
        class="cursor-pointer mx-1"
        :class="[
          (!isActiveStepValid && (isValidationEnabled)) && 'stepper-steps-invalid',
          activeOrCompletedStepsClasses(index),
        ]"
        @click="() => !item.disabled ? $emit('update:current-step', index) : null"
      >
        <!-- SECTION stepper step with icon -->
        <template v-if="item.icon">
          <div class="stepper-icon-step text-high-emphasis d-flex align-center gap-2">
            <!-- 👉 icon and title -->
            <div
              class="d-flex align-center gap-2 step-wrapper"
              :class="[direction === 'horizontal' && 'flex-column']"
            >
              <div class="stepper-icon">
                <v-icon
                  :size="item.size || iconSize"
                  :color="resolveColor(item)"
                >
                  {{ item.icon }}
                </v-icon>
              </div>

              <div>
                <p
                  class="stepper-title text-h6 mb-0"
                  :style="{'color': resolveTextColor(item)}"
                >
                  {{ item.title }}
                </p>
                <span
                  v-if="item.subtitle"
                  class="stepper-subtitle text-sm"
                >{{ item.subtitle }}</span>
              </div>
            </div>

            <!-- 👉 append chevron -->
            <v-icon
              v-if="isHorizontalAndNotLastStep(index)"
              class="flip-in-rtl stepper-chevron-indicator mx-6"
              size="18"
            >
              {{ icons.mdiChevronRight }}
            </v-icon>
          </div>
        </template>
        <!-- !SECTION  -->

        <!-- SECTION stepper step without icon -->
        <template v-else>
          <div class="d-flex align-center gap-x-2">
            <div class="d-flex align-center gap-2">
              <div
                class="d-flex align-center justify-center"
                style="block-size: 24px; inline-size: 24px;"
              >
                <!-- 👉 custom circle icon -->
                <template v-if="index >= computedCurrentStep">
                  <div
                    v-if="(!isValidationEnabled || isActiveStepValid || index !== computedCurrentStep)"
                    class="stepper-step-indicator"
                  />

                  <v-icon
                    v-else
                    size="24"
                    color="error"
                  >
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </template>

                <!-- 👉 step completed icon -->

                <v-icon
                  v-else
                  class="stepper-step-icon"
                  size="24"
                >
                  {{ icosn.mdiCheckCircleOutline }}
                </v-icon>
              </div>

              <!-- 👉 Step Number -->
              <h4 class="text-h4 step-number">
                {{ (index + 1).toString().padStart(2, '0') }}
              </h4>
            </div>

            <!-- 👉 title and subtitle -->
            <div style="line-height: 0;">
              <h6 class="text-sm font-weight-medium step-title">
                {{ item.title }}
              </h6>

              <span
                v-if="item.subtitle"
                class="text-xs step-subtitle"
              >
                {{ item.subtitle }}
              </span>
            </div>

            <!-- 👉 stepper step line -->
            <div
              v-if="isHorizontalAndNotLastStep(index)"
              class="stepper-step-line"
            />
          </div>
        </template>
        <!-- !SECTION  -->
      </div>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mdiCheckCircleOutline, mdiAlertCircleOutline, mdiChevronRight } from '@mdi/js'

export default {
  model: {
    prop: 'currentStep',
    event: 'update:current-step',
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
      default: 0,
    },
    direction: {
      type: String,
      required: false,
      default: 'horizontal',
    },
    iconSize: {
      type: [
        String,
        Number,
      ],
      required: false,
      default: 52,
    },
    isActiveStepValid: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    align: {
      type: String,
      required: false,
      default: 'center',
    },
  },
  setup(props, { emit }) {
    const computedCurrentStep = computed({
      get: () => props.currentStep,
      set: val => {
        emit('update:current-step', val)
      },
    })

    const isHorizontalAndNotLastStep = computed(() => index => props.direction === 'horizontal' && props.items.length - 1 !== index)

    // check if validation is enabled
    const isValidationEnabled = computed(() => props.isActiveStepValid !== undefined)

    const resolveColor = item => {
      if (computedCurrentStep.value >= item.tab) return 'secondary'

      return 'primary'
    }

    const resolveTextColor = item => {
      if (computedCurrentStep.value >= item.tab) return '#FF4C51'

      return '#DCDCDC'
    }

    const activateStepper = index => {
      if (!isValidationEnabled) computedCurrentStep.value = index
    }

    const activeOrCompletedStepsClasses = index => {
      if (index < computedCurrentStep.value) return 'stepper-steps-completed'
      if (index === computedCurrentStep.value) return 'stepper-steps-active'

      return ''
    }

    return {
      computedCurrentStep,
      isHorizontalAndNotLastStep,
      isValidationEnabled,

      activeOrCompletedStepsClasses,
      activateStepper,
      resolveColor,
      resolveTextColor,

      icons: {
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiChevronRight,
      },
    }
  },
}
</script>

<style lang="scss">
.app-stepper {
  // 👉 stepper step with bg color
  &.stepper-icon-step-bg {
    .stepper-icon-step {
      .step-wrapper {
        flex-direction: row !important;
      }

      .stepper-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.3125rem;
        background-color: rgba(var(--v-theme-on-surface), var(--v-selected-opacity));
        block-size: 2.5rem;
        color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
        inline-size: 2.5rem;
        margin-inline-end: 0.3rem;
      }

      .stepper-title,
      .stepper-subtitle {
        font-weight: 400 !important;
        line-height: normal;
      }

      .stepper-title {
        color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
        font-size: 1rem;
      }

      .stepper-subtitle {
        color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity));
        font-size: 0.85rem;
      }
    }

    .stepper-steps-active {
      .stepper-icon-step {
        .stepper-icon {
          background-color: rgb(var(--v-theme-primary));
          color: rgba(var(--v-theme-on-primary));
        }
      }
    }

    .stepper-steps-completed {
      .stepper-icon-step {
        .stepper-icon {
          background: rgba(var(--v-theme-primary), 0.08);
          color: rgba(var(--v-theme-primary));
        }
      }
    }
  }

  // 👉 stepper step with icon and  default
  .v-slide-group__content {
    row-gap: 1.5rem;

    .stepper-step-indicator {
      border: 0.3125rem solid rgb(var(--v-theme-primary));
      border-radius: 50%;
      background-color: rgb(var(--v-theme-surface));
      block-size: 1.25rem;
      inline-size: 1.25rem;
      opacity: var(--v-activated-opacity);
    }

    .stepper-step-line {
      border-radius: 0.1875rem;
      background-color: rgb(var(--v-theme-primary));
      block-size: 0.1875rem;
      inline-size: 3.75rem;
      opacity: var(--v-activated-opacity);
    }

    .stepper-chevron-indicator {
      color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity));
    }

    .stepper-steps-completed,
    .stepper-steps-active {
      .stepper-icon-step,
      .stepper-step-icon {
        color: rgb(var(--v-theme-primary)) !important;
      }

      .stepper-step-indicator {
        opacity: 1;
      }
    }

    .stepper-steps-completed {
      .stepper-step-line {
        opacity: 1;
      }

      .stepper-chevron-indicator {
        color: rgb(var(--v-theme-primary));
      }
    }

    .stepper-steps-invalid.stepper-steps-active {
      .stepper-icon-step,
      .step-number,
      .step-title,
      .step-subtitle {
        color: rgb(var(--v-theme-error)) !important;
      }
    }
  }

  // 👉 stepper alignment
  &.app-stepper-center {
    .v-slide-group__content {
      justify-content: center;
    }
  }

  &.app-stepper-start {
    .v-slide-group__content {
      justify-content: start;
    }
  }

  &.app-stepper-end {
    .v-slide-group__content {
      justify-content: end;
    }
  }
}
</style>
