<template>
  <v-row v-if="checkoutCartDataLocal">
    <v-col
      cols="12"
      md="7"
    >
      <div class="border rounded">
        <v-row class="card-scroll">
          <v-col
            v-for="(item, index) in computedCartItems"
            :key="item.id"
            cols="12"
            sm="3"
            md="3"
            class="cursor-pointer"
            @click="() => $emit('addProduct', item)"
          >
            <div
              class="d-flex align-center justify-center gap-6 pa-1 position-relative flex-column"
              :class="index ? 'border-t' : ''"
            >
              <v-img
                :src="item.image"
                width="100"
                height="100"
                aspect-ratio="1"
                contain
              />
              <span class="text-h6 font-weight-regular my-2 text-center">
                {{ `${item.name} (${formatCurrency(item.unit_price)})` }}
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-col
      cols="12"
      md="5"
    >
      <card-detail
        :checkout-data="checkoutCartDataLocal"
        :show-actions="computedCurrentStep===0"
        @addClient="() => $emit('addClient', true)"
        @addTip="$emit('addTip')"
        @addProduct="val => $emit('addProduct', val)"
        @updateReservation="$emit('updateReservation')"
        @removeProduct="val => $emit('removeProduct', val)"
        @removeAllProduct="val => $emit('removeAllProduct', val)"
        @cancel="$emit('cancel')"
        @openTab="$emit('openTab')"
        @updateStep="nextStep"
      />
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-return-assign */
import { ref, computed } from '@vue/composition-api'
import { formatCurrency } from '@core/utils'
import { mdiCurrencyUsd, mdiSoccerField } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

import CardDetail from '@/views/checkout/components/CartDetail.vue'

export default {
  components: {
    CardDetail,
  },
  model: {
    prop: 'currentStep',
    event: 'update:current-step',
  },
  props: {
    currentStep: {
      type: Number,
      default: 0,
      required: false,
    },
    checkoutData: {
      type: null,
      required: true,
    },
    cartItems: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const client = ref(null)
    const showClients = ref(false)

    const checkoutCartDataLocal = computed({
      get: () => props.checkoutData,
      set: val => {
        emit('update:checkout-data', val)
      },
    })
    const computedCurrentStep = computed({
      get: () => props.currentStep,
      set: val => {
        emit('updateStep', val || 0)
      },
    })
    const computedCartItems = computed(() => props.cartItems)

    const nextStep = () => computedCurrentStep.value += 1

    return {
      client,
      showClients,
      checkoutCartDataLocal,
      computedCurrentStep,
      computedCartItems,

      nextStep,
      formatCurrency,

      t,

      icons: {
        mdiCurrencyUsd,
        mdiSoccerField,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-item-remove-btn {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 10px;
}

.border-dark {
  border: 1px solid rgba(255, 255, 255, 0.22);
}

.border-ligth {
  border: 1px solid rgba(58, 53, 65, 0.14);
}

.card-scroll {
  max-height: 740px;
  overflow-y: scroll;
}
</style>
