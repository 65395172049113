<template>
  <v-dialog
    v-model="isModalOpen"
    max-width="700px"
    persistent
  >
    <v-card
      class="pa-3 relative-card"
      rounded="10"
      style="width: 100%"
    >
      <v-icon
        class="absolute-close"
        @click="$emit('update:is-modal-open', false)"
      >
        {{ icons.mdiClose }}
      </v-icon>

      <v-form
        ref="form"
        @submit.prevent="onSave"
      >
        <div>
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title class="text-h6 white--text pl-0">
              {{ t('tooltip.add') }} Tip
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center">
            <v-row class="justify-center align-center px-5">
              <v-col
                cols="12"
                md="auto"
              >
                <v-btn
                  class="ma-0 mr-2 text-h6"
                  color="secondary"
                  @click="onAddTip(1)"
                >
                  $1
                </v-btn>

                <v-btn
                  class="ma-0 mr-2 text-h6"
                  color="secondary"
                  @click="onAddTip(2)"
                >
                  $2
                </v-btn>

                <v-btn
                  class="ma-0 text-h6"
                  color="secondary"
                  @click="onAddTip(5)"
                >
                  $5
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="customTip"
                  class="text-h6"
                  outlined
                  prefix="$"
                  hide-details
                  :rules="[regexValidator(customTip, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
                  @input="e => {
                    if (!charUnique(e, '.')) {
                      customTip = null
                    }
                  }"
                  @keypress="onlyNumberDot"
                />
              </v-col>

              <v-col
                cols="12"
                md="2"
                class="ml-auto"
              >
                <v-btn
                  class="text-h6"
                  color="secondary"
                  dark
                  @click="onAddTip(customTip)"
                >
                  {{ t('tooltip.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mdiPlus, mdiClose } from '@mdi/js'
import { required, regexValidator, charUnique } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { onlyNumberDot } from '@core/utils/functionsHelpers'
import { error } from '@core/utils/toasted'

export default {
  name: 'Tip',
  model: {
    prop: 'isModalOpen',
    event: 'update:is-modal-open',
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const form = ref(null)
    const client = ref(null)
    const selectedField = ref(null)
    const openedPanels = ref([])
    const customTip = ref(0)
    const guestName = ref(null)

    const players = ref([
      {
        avatar: '',
        name: 'Caroline Black',
        status: 'Online',
        lastVisited: '13 minutes ago',
      },
      {
        avatar: '',
        name: 'Alfred Copeland',
        status: 'Away',
        lastVisited: '11 minutes ago',
      },
      {
        avatar: '',
        name: 'Celia Schneider',
        status: 'Offline',
        lastVisited: '9 minutes ago',
      },
      {
        avatar: '',
        name: 'Max Rogan',
        status: 'In Meeting',
        lastVisited: '28 minutes ago',
      },
    ])

    const computedIsModalOpen = computed(() => props.isModalOpen)

    const onSave = () => {
      if (form.value.validate()) {
        emit('update:is-modal-open', false)
      }
    }

    const onAddTip = tip => {
      if (tip > 0) {
        emit('addTip', tip)
        emit('update:is-modal-open', false)
      } else error('tip must be greater than zero')
    }

    watch([computedIsModalOpen], () => {
      if (computedIsModalOpen.value) {
        setTimeout(() => {
          // openedPanels.value = computedFields.value.map((e, i) => i)
        }, 200)
      } else {
        form.value.reset()
      }
    })

    return {
      // data
      form,
      client,
      selectedField,
      openedPanels,
      players,
      customTip,
      guestName,

      // computed
      computedIsModalOpen,

      // methods
      onSave,
      onAddTip,

      required,
      regexValidator,
      charUnique,
      avatarText,
      onlyNumberDot,

      // i18n
      t,

      // icons
      icons: {
        mdiPlus,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .border-dark {
    border: 1px solid rgba(255, 255, 255, 0.22);
  }

  .border-ligth {
    border: 1px solid rgba(58, 53, 65, 0.14);
  }

.absolute-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #222222;
  color: #ffffff6b;
  border-radius: 50px !important;
  padding: 2px;
  cursor: pointer;
  z-index: 1;
}
.relative-card {
  position: relative;
}
</style>
