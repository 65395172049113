<template>
  <div>
    <v-card
      :class="{'border-dark': $vuetify.theme.dark, 'border-ligth': !$vuetify.theme.dark }"
    >
      <!-- 👉 Custom details -->
      <v-card-text>
        <div class="d-flex align-center text-high-emphasis">
          <v-text-field
            v-model="clientName"
            label="Client"
            class="text-h6"
            outlined
            readonly
            :clearable="false"
            hide-details
          />
        </div>
      </v-card-text>

      <v-divider />

      <!-- 👉 Sales details -->
      <v-card-text>
        <h6 class="text-h5 font-weight-medium mb-4">
          {{ t('checkout.sales_details') }}
        </h6>

        <div class="text-high-emphasis">
          <div
            v-for="sale in checkoutCartDataLocal.products"
            :key="sale.id"
            class="d-flex justify-space-between align-center mb-2"
          >
            <span class="text-h6 d-flex align-center">
              <v-icon
                v-if="computedShowActions && sale.id !== 1 && !sale.amount_suggested && sale.visibility !== 'R'"
                color="error"
                class="cursor-pointer"
                @click="() => $emit('removeProduct', sale)"
              >
                {{ icons.mdiMinus }}
              </v-icon>

              <span
                v-if="sale.id !== 1 && !sale.amount_suggested && sale.visibility !== 'R'"
                class="mx-2"
              >{{ sale.quantity }}</span>

              <v-icon
                v-if="computedShowActions && sale.id !== 1 && !sale.amount_suggested && sale.visibility !== 'R'"
                color="success"
                class="cursor-pointer"
                @click="() => $emit('addProduct', sale)"
              >
                {{ icons.mdiPlus }}
              </v-icon>

              <span
                class="mr-2"
                :class="{ 'ml-3': sale.id !== 1 && !sale.amount_suggested }"
              >
                {{ sale.name }}
              </span>

              <v-icon
                v-if="computedShowActions && sale.id !== 1 && !sale.amount_suggested && sale.visibility !== 'R'"
                color="secondary"
                class="cursor-pointer"
                @click="() => $emit('removeAllProduct', sale)"
              >
                {{ icons.mdiClose }}
              </v-icon>
            </span>
            <span class="text-h6">{{ formatCurrency(sale.subtotal) }}</span>
          </div>
        </div>
      </v-card-text>

      <v-divider />

      <!-- 👉 Price details -->
      <v-card-text>
        <h6 class="text-h5 font-weight-medium mb-4">
          {{ t('checkout.price_details') }}
        </h6>

        <div class="text-high-emphasis text-h6">
          <div class="d-flex justify-space-between mb-2">
            <span>Subtotal</span>
            <span>{{ formatCurrency(computedSubtotal) }}</span>
          </div>

          <div class="d-flex justify-space-between mb-2">
            <span>{{ t('checkout.taxes_and_fees') }}</span>
            <span>{{ formatCurrency(computedTax) }}</span>
          </div>

          <div class="d-flex justify-space-between mb-2">
            <span>{{ t('checkout.tip') }}</span>
            <span>{{ formatCurrency(checkoutCartDataLocal.tip) }}</span>
          </div>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-text class="d-flex justify-space-between py-4">
        <h6 class="text-h5 font-weight-medium">
          Total
        </h6>
        <h6
          class="text-h5 font-weight-medium success--text"
          :style="{
            color: '#56ca00 !important',
            'caret-color': '#56ca00 !important',
          }"
        >
          {{ formatCurrency(computedAmount) }}
        </h6>
      </v-card-text>
    </v-card>

    <v-btn
      v-if="computedShowActions"
      block
      large
      height="46px !important"
      class="mt-4 white--text text-h6"
      color="#4b8521"
      :disabled="computedIsRevervation"
      @click="$emit('updateStep')"
    >
      {{ t('checkout.place_order') }}
    </v-btn>

    <v-btn
      v-if="computedShowActions"
      block
      large
      height="46px !important"
      class="mt-4 white--text text-h6"
      color="#4b8521"
      @click="computedIsRevervation ? $emit('updateReservation') : $emit('addClient', true)"
    >
      <v-icon class="mr-2">
        {{ icons.mdiSoccerField }}
      </v-icon>
      {{ computedIsRevervation ? t('reservations.reservation_save') : t('reservations.reservation') }}
    </v-btn>

    <v-btn
      v-if="computedShowActions"
      block
      large
      height="46px !important"
      class="mt-4 white--text text-h6"
      color="#4b8521"
      :disabled="computedIsRevervation"
      @click="$emit('addTip')"
    >
      <v-icon class="mr-2">
        {{ icons.mdiCurrencyUsd }}
      </v-icon>
      {{ t('checkout.tip') }}
    </v-btn>

    <div class="d-flex justify-space-between">
      <v-btn
        v-if="computedShowActions"
        class="mt-4 text-h6"
        large
        width="45%"
        color="secondary"
        :disabled="!checkoutCartDataLocal.fullname && !checkoutCartDataLocal.guest_name"
        @click="$emit('openTab')"
      >
        {{ checkoutCartDataLocal.invoice_id ? t('checkout.keep_open') : t('checkout.open_tab') }}
      </v-btn>

      <v-btn
        v-if="computedShowActions"
        class="mt-4 text-h6"
        large
        width="45%"
        color="secondary"
        :disabled="(!!checkoutCartDataLocal.fullname || !!checkoutCartDataLocal.guest_name) && !!checkoutCartDataLocal.invoice_id"
        @click="onCancel"
      >
        {{ t('tooltip.cancel') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-return-assign */
import { ref, computed } from '@vue/composition-api'
import { formatCurrency } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import Swal from 'sweetalert2'
import {
  mdiPlus,
  mdiMinus,
  mdiClose,
  mdiMagnify,
  mdiSoccerField,
  mdiCurrencyUsd,
} from '@mdi/js'

export default {
  props: {
    checkoutData: {
      type: null,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const client = ref(null)
    const checkoutCartDataLocal = computed({
      get: () => props.checkoutData,
      set: val => {
        emit('update:checkout-data', val)
      },
    })
    const computedShowActions = computed(() => props.showActions)
    const computedIsRevervation = computed(() => !!checkoutCartDataLocal.value.reservation_id && !checkoutCartDataLocal.value.user_id && !checkoutCartDataLocal.value.guest_name)

    const clientName = computed(() => {
      if (checkoutCartDataLocal.value.fullname) return checkoutCartDataLocal.value.fullname
      if (checkoutCartDataLocal.value.guest_name) return checkoutCartDataLocal.value.guest_name

      return checkoutCartDataLocal.value.reservation_name
    })
    const computedSubtotal = computed(() => checkoutCartDataLocal.value.subtotal = checkoutCartDataLocal.value.products.reduce((acc, item) => acc + item.subtotal, 0))
    const computedTax = computed(() => checkoutCartDataLocal.value.tax = checkoutCartDataLocal.value.products.reduce((acc, item) => acc + item.tax, 0))
    const computedAmount = computed(() => checkoutCartDataLocal.value.amount = checkoutCartDataLocal.value.products.reduce((acc, item) => acc + item.amount, 0) + checkoutCartDataLocal.value.tip)

    const onCancel = async () => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          emit('cancel')
        }
      })
    }

    return {
      client,
      checkoutCartDataLocal,
      computedShowActions,
      computedIsRevervation,
      clientName,
      computedSubtotal,
      computedTax,
      computedAmount,

      onCancel,
      formatCurrency,
      t,

      icons: {
        mdiPlus,
        mdiMinus,
        mdiClose,
        mdiMagnify,
        mdiSoccerField,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-item-remove-btn {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 10px;
}

.border-dark {
  border: 1px solid rgba(255, 255, 255, 0.22);
}

.border-ligth {
  border: 1px solid rgba(58, 53, 65, 0.14);
}
</style>
