export const PAYMENTS = [
  {
    id: 1,
    name: 'Card Reader',
  },
  {
    id: 2,
    name: 'Card Manual',
  },
  {
    id: 3,
    name: 'Cash',
  },
]

export const CASH_DIGITS = [
  {
    value: 0.25,
    text: '$0.25',
  },
  {
    value: 0.50,
    text: '$0.50',
  },
  {
    value: 1,
    text: '$1',
  },
  {
    value: 2,
    text: '$2',
  },
  {
    value: 5,
    text: '$5',
  },
  {
    value: 10,
    text: '$10',
  },
  {
    value: 20,
    text: '$20',
  },
  {
    value: 50,
    text: '$50',
  },
]
