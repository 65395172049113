<template>
  <div>
    <v-card>
      <v-card-text>
        <!-- 👉 Stepper -->
        <v-row align="center">
          <v-col
            cols="12"
            md="3"
          />
          <v-col
            cols="12"
            md="6"
          >
            <app-stepper
              v-model="currentStep"
              :current-step="currentStep"
              class="checkout-stepper text-h6"
              icon-size="60"
              :items="computedCheckoutSteps"
              :direction="$vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical'"
              :align="'center'"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="selectedTerminal"
              :label="t('terminals.terminal_name')"
              :items="terminals"
              item-text="name"
              item-value="id"
              class="text-h6"
              outlined
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text>
        <!-- 👉 stepper content -->
        <v-window
          v-model="currentStep"
          class="disable-tab-transition"
        >
          <v-window-item>
            <cart-content
              :current-step="currentStep"
              :checkout-data="checkoutData"
              :cart-items="computedCartItems"
              @updateStep="updateStep"
              @addProduct="onAddProduct"
              @addTip="() => isTip = true"
              @removeProduct="onRemoveProduct"
              @removeAllProduct="onRemoveAllProduct"
              @addClient="openAddClient"
              @updateReservation="onUpdateReservation"
              @cancel="onCancel"
              @openTab="onOpenTab"
            />
          </v-window-item>

          <v-window-item>
            <payment-content
              :current-step="currentStep"
              :checkout-data="checkoutData"
              :payment-data="paymentData"
              @updateStep="updateStep"
              @pay="payInvoice"
            />
          </v-window-item>

          <v-window-item>
            <confirmation-content
              :checkout-data="checkoutData"
              @updateStep="updateStep"
              @finish="onFinish"
            />
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

    <v-card
      v-if="currentStep === 0"
      class="mt-4"
    >
      <v-card-title class="text-h6">
        {{ t('checkout.open_tabs') }}
      </v-card-title>

      <v-card-text class="d-flex justify-space-between">
        <div class="d-flex justify-start gap-3 open-tab-scroll">
          <v-card
            v-for="ckeckout in checkouts"
            :key="ckeckout.id"
            min-width="220"
            class="d-flex justify-center align-center"
            :class="{'border-dark': $vuetify.theme.dark, 'border-ligth': !$vuetify.theme.dark }"
            @click="onSelectInvoice(ckeckout)"
          >
            <v-card-title class="text-body-1 pa-2">
              {{ `${ckeckout.fullname} (${ckeckout.amount ? formatCurrency(ckeckout.amount) : '$0.00'})` }}
            </v-card-title>

            <v-card-text
              class="pa-2 pl-0 d-flex"
              style="width: auto;"
            >
              <v-icon
                color="error"
                size="22"
                @click="deleteInvoiceOpen(ckeckout.invoice_id)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <add-client
      v-model="isAddClient"
      :fields="fields"
      :add-client-data="addClientData"
      @addGuest="fetchAddGuest"
      @addClient="onAddClient"
      @addReservation="onAddReservation"
    />

    <tip
      v-model="isTip"
      :fields="fields"
      @addTip="onAddTip"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, onMounted } from '@vue/composition-api'
import { formatCurrency, roundNum, useRouter } from '@core/utils'
import { mdiDeleteOutline } from '@mdi/js'
import Swal from 'sweetalert2'
import {
  getReservationPosProducts,
  updateReservationPosProducts,
  getInvoicePos,
} from '@api'

import AppStepper from '@core/components/app-stepper/AppStepper.vue'
import CartContent from './components/Cart.vue'
import PaymentContent from './components/Payment.vue'
import ConfirmationContent from './components/Confirmation.vue'
import AddClient from './components/AddClient.vue'
import Tip from './components/Tip.vue'

import useCheckout from './useCheckout'

export default {
  components: {
    AppStepper,
    CartContent,
    PaymentContent,
    ConfirmationContent,
    AddClient,
    Tip,
  },
  setup() {
    const { router, route } = useRouter()
    const {
      configFacility,
      currentStep,
      checkoutsAll,
      checkouts,
      cartItems,
      computedCartItems,
      checkoutSteps,
      computedCheckoutSteps,
      checkoutDataInit,
      checkoutData,
      fields,
      terminals,
      selectedTerminal,
      paymentData,
      userData,

      t,

      fetchProducts,
      fetchReservations,
      fetchFields,
      fetchTerminals,
      fetchAddGuest,
      payInvoice,
      deleteInvoiceOpen,
    } = useCheckout()

    const isAddClient = ref(false)
    const selectedClient = ref({})
    const isTip = ref(false)
    const addData = ref(null)
    const addClientData = ref({ reservation: false })
    const reservationFetchData = ref(null)

    const onCancel = () => {
      checkoutData.value = {
        invoice_id: null,
        facility_id: configFacility.value,
        reservation_name: null,
        reservation_id: null,
        user_id: null,
        user_console_id: userData.value.username,
        fullname: null,
        guest_name: null,
        payment_info: null,
        email: null,
        phone_number: null,
        products: [],
        subtotal: 0,
        discount: 0,
        tax: 0,
        tip: 0,
        amount: 0,
        is_open_tab: null,
      }

      paymentData.value = {
        payment_method_id: null,
        terminal_id: selectedTerminal.value,
        facility_id: configFacility.value,
        user_console_id: userData.value.username,

        amount: 0,
        guest_name: null,
        email: userData.value.email || null,
        phone_number: userData.value.phone_number || null,
        user_id: null,

        info: {
          payment_method_name: null,
          observation: null,
          invoce_amount: null,
          client_amount: null,
          change: null,
        },
      }

      if (addClientData.value.reservation) {
        router.push({
          name: 'views-reservations-schedule',
          params: {
            reservationParams: reservationFetchData.value,
          },
        })
      }
    }

    const onRemoveAllProduct = item => {
      const filteredProducts = checkoutData.value.products.filter(i => i.id !== item.id)
      checkoutData.value.products = filteredProducts
    }

    const onRemoveProduct = item => {
      if (item.quantity <= 1 && item.editable) onRemoveAllProduct(item)
      else if (item.quantity > 1) {
        const mapSales = checkoutData.value.products.map(u => {
          if (u.id !== item.id) return u

          const mapQty = u.quantity - 1
          const mapSubtotal = mapQty * item.unit_price
          const mapTax = roundNum(mapSubtotal * (item.tax_percent / 100))
          const mapAmount = mapSubtotal + mapTax

          return {
            ...item,
            quantity: mapQty,
            subtotal: mapSubtotal,
            tax: mapTax,
            amount: mapAmount,
          }
        })
        checkoutData.value.products = mapSales
      }
    }

    const onAddProduct = item => {
      if (checkoutData.value.products.find(e => e.id === item.id)) {
        const mapSales = checkoutData.value.products.map(u => {
          if (u.id !== item.id) return u

          const mapQty = u.quantity + 1
          const mapSubtotal = mapQty * item.unit_price
          const mapTax = roundNum(mapSubtotal * (item.tax_percent / 100))
          const mapAmount = mapSubtotal + mapTax

          return {
            ...item,
            quantity: mapQty,
            subtotal: mapSubtotal,
            tax: mapTax,
            amount: mapAmount,
          }
        })
        checkoutData.value.products = mapSales
      } else {
        checkoutData.value.products.push({
          ...item,
          total: item.unit_price,
          quantity: 1,

          subtotal: item.unit_price,
          tax: item.unit_price * (item.tax_percent / 100),
          amount: item.unit_price + (item.unit_price * (item.tax_percent / 100)),
        })
      }
    }

    const onAddClient = (item, reservationItems) => {
      addData.value = item
      isAddClient.value = false

      checkoutData.value.fullname = null
      checkoutData.value.guest_name = null
      checkoutData.value.user_id = null
      checkoutData.value.reservation_id = null
      checkoutData.value.reservation_name = null

      paymentData.value.fullname = null
      paymentData.value.guest_name = null
      paymentData.value.user_id = null

      if (!!reservationItems && reservationItems.length) {
        checkoutData.value.products = reservationItems
      }

      if (item.username) {
        paymentData.value.fullname = item.fullname
        checkoutData.value.fullname = item.fullname
      } else {
        paymentData.value.guest_name = item.fullname
        checkoutData.value.guest_name = item.fullname
      }
      paymentData.value.user_id = item.username
      checkoutData.value.user_id = item.username
      checkoutData.value.reservation_id = item.reservation_id
      checkoutData.value.reservation_name = item.reservation_name
    }

    const onAddReservation = async item => {
      const resp = await getReservationPosProducts(item.reservation_id)
      onAddClient(item, resp.data)
    }

    const onUpdateReservation = async () => {
      const resp = await updateReservationPosProducts(checkoutData.value.reservation_id, checkoutData.value)
      if (resp.ok) onCancel()
    }

    const onAddTip = item => {
      if (item) checkoutData.value.tip = item
    }

    const updateStep = val => {
      if (currentStep.value === 0) {
        if (checkoutData.value.amount > 0) {
          currentStep.value = val
          checkoutSteps.value[1].disabled = false
        } else {
          Swal.fire({
            title: 'Debes seleccionar productos',
            icon: 'info',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
          })
        }
      }

      if (currentStep.value === 1) {
        currentStep.value = val
      }

      if (currentStep.value === 2) {
        currentStep.value = val
      }
    }

    const onFinish = () => {
      onCancel()
      updateStep(0)
    }

    const onOpenTab = async () => {
      checkoutData.value.is_open_tab = true
      await payInvoice(true, false)
      onCancel()
      await fetchReservations()
    }

    const onAddCheckout = () => {
      checkoutData.value = {
        invoice_id: null,
        facility_id: null,
        reservation_name: null,
        reservation_id: null,
        user_id: null,
        fullname: null,
        guest_name: null,
        payment_info: null,
        email: null,
        phone_number: null,
        products: [],
        subtotal: 0,
        discount: 0,
        tax: 0,
        tip: 0,
        amount: 0,
        is_open_tab: null,
      }
      checkouts.value = checkoutsAll.value.filter(e => e.invoice_id !== checkoutDataInit.invoice_id)
    }

    const onSelectInvoice = async invoice => {
      checkouts.value = checkoutsAll.value.filter(e => e.invoice_id !== invoice.invoice_id)

      // checkoutData.value = invoice
      const resp = await getInvoicePos(invoice.invoice_id)
      checkoutData.value = resp.data
    }

    const openAddClient = async () => {
      await fetchFields()
      isAddClient.value = true
    }

    onMounted(async () => {
      const { addClient, fetchParams } = route.value.params
      if (addClient && addClient.reservation) {
        addClientData.value = addClient
        reservationFetchData.value = fetchParams
        await openAddClient()
      }

      await fetchProducts()
      await fetchReservations()
      await fetchFields()
      await fetchTerminals()
    })

    return {
      configFacility,

      checkoutSteps,
      computedCheckoutSteps,
      checkoutDataInit,
      checkoutData,
      checkouts,
      currentStep,
      paymentData,

      cartItems,
      computedCartItems,
      selectedClient,
      isAddClient,
      isTip,
      fields,
      terminals,
      selectedTerminal,
      addData,
      addClientData,

      t,

      onRemoveAllProduct,
      onRemoveProduct,
      onAddProduct,
      onAddCheckout,
      onCancel,
      onSelectInvoice,
      formatCurrency,
      updateStep,
      fetchAddGuest,
      payInvoice,
      onAddTip,
      onAddClient,
      onAddReservation,
      onUpdateReservation,
      onFinish,
      onOpenTab,
      openAddClient,
      deleteInvoiceOpen,

      icons: {
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.checkout-stepper {
  .stepper-icon-step {
    .step-wrapper + svg {
      margin-inline: 3.5rem !important;
    }
  }
}

.border-dark {
  border: 1px solid rgba(255, 255, 255, 0.22) !important;
}

.border-ligth {
  border: 1px solid rgba(58, 53, 65, 0.14) !important;
}

.gap-3 {
  gap: 12px;
}

.step-active {
  color: 'secondary';
}

.open-tab-scroll {
  max-width: 100%;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 10px;
}
</style>
