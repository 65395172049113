<template>
  <v-row style="min-height: 380px;">
    <v-col
      cols="12"
      md="7"
    >
      <v-btn-toggle
        v-model="selectedPaymentMethod"
        mandatory
        tile
        group
        dense
        color="secondary"
        class="d-flex justify-center text-h5"
      >
        <v-btn
          :value="0"
          class="text-h6"
        >
          {{ t('checkout.reader') }}
        </v-btn>

        <v-btn
          :value="1"
          class="text-h6"
        >
          {{ t('checkout.cash') }}
        </v-btn>

        <v-btn
          :value="2"
          class="text-h6"
        >
          {{ t('checkout.manual') }}
        </v-btn>

        <v-btn
          :value="3"
          class="text-h6"
        >
          {{ t('checkout.others') }}
        </v-btn>
      </v-btn-toggle>

      <v-window
        v-model="selectedPaymentMethod"
        class="mt-3 ma-auto"
        :style="'min-height: 300px'"
      >
        <v-window-item
          :style="'min-height: 300px'"
        >
          <v-row
            justify="center"
            class="mt-2"
          >
            <v-col
              cols="12"
              sm="10"
              class="d-flex justify-center flex-column"
            >
              <p class="text-center text-h6">
                {{ t('checkout.continue_to_read') }}
              </p>
              <!-- <v-btn
                v-if="showReadCard"
                class="mt-2"
                color="secondary"
                @click="onReadCard"
              >
                Read Card
              </v-btn>

              <v-btn
                v-if="!showReadCard"
                class="mt-2"
                color="secondary"
                @click="onSimCard"
              >
                Simular Pago
              </v-btn> -->
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item
          :style="'min-height: 300px'"
        >
          <v-row
            justify="center"
            class="mt-2"
          >
            <v-col
              cols="12"
              sm="7"
            >
              <v-row>
                <v-col
                  v-for="digit in cashDigitsOptions"
                  :key="digit.value"
                  cols="12"
                  sm="4"
                >
                  <v-btn
                    class="text-h6 pa-0"
                    height="60px !important"
                    color="secondary"
                    block
                    @click="onAddCash(digit.value)"
                  >
                    {{ digit.text }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              sm="5"
            >
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="cardFormData.cash_amount"
                  :label="t('checkout.cash_amount')"
                  prefix="$"
                  outlined
                  class="text-h6"
                  @input="e => {
                    if (!validations.charUnique(e, '.')) {
                      cardFormData.cash_amount = 0
                    }
                  }"
                  @keypress="onlyNumberDot"
                />
              </v-col>

              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="computedCashChange"
                  :label="t('checkout.change')"
                  prefix="$"
                  outlined
                  class="text-h6"
                  readonly
                />
              </v-col>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item
          :style="'min-height: 300px'"
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              sm="10"
              class="d-flex justify-center flex-column"
            >
              <form
                id="payment-form"
                ref="form"
                @submit.prevent="formSubmitStripe"
              >
                <div id="link-authentication-element" />
                <div id="payment-element" />
              </form>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item
          :style="'min-height: 300px'"
        >
          <v-row
            justify="center"
            class="mt-2"
          >
            <v-col
              cols="12"
            >
              <v-select
                v-model="OSPMethodsSelected"
                :label="t('payment_methods.payment_method')"
                :items="OSPMethodsOptions"
                item-value="id"
                item-text="name"
                class="text-h6"
                outlined
              />
            </v-col>

            <v-col
              cols="12"
            >
              <v-text-field
                v-model="cardFormData.note"
                :label="t('transactions.note')"
                class="text-h6"
                outlined
              />
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>

      <v-row
        justify="center"
        class="mt-2"
      >
        <v-col
          cols="12"
          class="d-flex justify-center flex-column"
        >
          <v-btn
            class="mt-2 text-h6"
            height="46px !important"
            color="secondary"
            block
            :disabled="isLoading"
            @click="onPay"
          >
            {{ t('transactions.pay') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      md="5"
    >
      <card-detail
        :checkout-data="checkoutPaymentDataLocal"
      />
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable arrow-body-style */
/* eslint-disable no-return-assign */
/* eslint-disable object-curly-newline */
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { formatCurrency } from '@core/utils'
import {
  required,
  minNumber,
  maxNumber,
  charUnique,
  regexValidator,
} from '@core/utils/validation'
import { onlyNumberDot } from '@core/utils/functionsHelpers'
import { loadStripe } from '@stripe/stripe-js'
import { error as toasdError } from '@/@core/utils/toasted'
import { useUtils } from '@core/libs/i18n'
import {
  initiatePaymentTerminal,
  SimCardPaymentTerminal,
  capturePaymentTerminal,
  getOnSitePaymentMethodsActiveByFacility,
  initiatePaymentInvoice,
} from '@api'
import { PAYMENTS, CASH_DIGITS } from '@/@core/utils/checkout'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import useCryptoJs from '@/@core/utils/useCryptoJs'

import CardDetail from '@/views/checkout/components/CartDetail.vue'

export default {
  components: {
    CardDetail,
  },
  props: {
    currentStep: {
      type: Number,
      default: 0,
      required: false,
    },
    checkoutData: {
      type: Object,
      required: true,
      default: () => {},
    },
    paymentData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { configFacility } = useSelectOptions()
    const { encrypt } = useCryptoJs()

    const checkoutPaymentDataLocal = computed({
      get: () => props.checkoutData,
      set: val => {
        emit('update:checkout-data', val)
      },
    })
    const paymentDataLocal = computed({
      get: () => props.paymentData,
      set: val => {
        emit('update:payment-data', val)
      },
    })

    const paymentsOptions = computed(() => PAYMENTS)
    const cashDigitsOptions = computed(() => CASH_DIGITS)

    const computedSubtotal = computed(() => checkoutPaymentDataLocal.value.subtotal = checkoutPaymentDataLocal.value.products.reduce((acc, item) => acc + item.subtotal, 0))
    const computedTax = computed(() => checkoutPaymentDataLocal.value.tax = checkoutPaymentDataLocal.value.products.reduce((acc, item) => acc + item.tax, 0))
    const computedAmount = computed(() => checkoutPaymentDataLocal.value.amount = checkoutPaymentDataLocal.value.products.reduce((acc, item) => acc + item.amount, 0) + checkoutPaymentDataLocal.value.tip)
    const computedCurrentStep = computed({
      get: () => props.currentStep,
      set: val => {
        emit('updateStep', val || 0)
      },
    })

    const selectedPaymentMethod = ref(0)
    const cardFormData = ref({
      cardNumber: null,
      cardName: '',
      cardExpiry: '',
      cardCvv: null,
      isCardSave: true,
      cash_amount: 0,
      cash_change: 0,
      note: null,
    })
    const computedCashChange = computed(() => {
      if (cardFormData.value.cash_amount > computedAmount.value) return Math.round((cardFormData.value.cash_amount - computedAmount.value) * 100) / 100

      return 0
    })

    const showReadCard = ref(true)
    const showSimPay = ref(false)
    const initiatePay = ref(null)
    const simPay = ref(null)

    const isLoading = ref(false)
    const isInitialized = ref(false)

    const form = ref(null)
    let stripe
    let elements
    const isDeclined = ref(false)

    const OSPMethodsSelected = ref(null)
    const OSPMethodsOptions = ref([])

    const giftCardFormData = ref({
      giftCardNumber: null,
      giftCardPin: null,
    })

    const updateCartData = () => {
      emit('update:checkout-data', checkoutPaymentDataLocal.value)
    }

    const nextStep = () => computedCurrentStep.value += 1

    const formSubmitStripe = async () => {
      try {
        if (isLoading.value) {
          return
        }

        const { error: submitError } = await elements.submit()
        if (submitError) {
          console.error({ submitError })

          return
        }

        // isLoading.value = true
        isDeclined.value = false
        const encryptAmount = encrypt(checkoutPaymentDataLocal.value.amount)
        const respPaymentIntent = await initiatePaymentInvoice(encryptAmount)
        const { client_secret: clientSecret } = respPaymentIntent.data

        //   if (saveCreditCard.value === 'Y') {
        //     const [piId] = clientSecret.split('_secret_')
        //     const respPaymentIntentUpdate = await putPaymentIntentUpdateStripe(piId)

        //     if (!respPaymentIntentUpdate.ok) {
        //       console.error({ respPaymentIntentUpdate })
        //       toasdError(respPaymentIntentUpdate.message)

        //       return
        //     }
        //   }

        const { paymentIntent, error } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${window.location.origin}/views/pay-finished/N`,
          },
          redirect: 'if_required',
        })

        if (error) {
          toasdError(error.message)

          isDeclined.value = true
          paymentDataLocal.value.amount = Math.round((checkoutPaymentDataLocal.value.amount) * 100) / 100
          paymentDataLocal.value.guest_name = checkoutPaymentDataLocal.value.guest_name
          paymentDataLocal.value.fullname = checkoutPaymentDataLocal.value.fullname
          paymentDataLocal.value.info = {
            payment_intent_id: error.payment_intent.id,
            payment_method_id: error.payment_method.id,
            charge: error.charge,
            code: error.code,
            decline_code: error.decline_code || null,
            message: error.message,
            status: 'declined',
          }

          return
        }

        if (paymentIntent.status !== 'succeeded') {
          console.error({ paymentIntent })
          toasdError('Payment process error!')

          return
        }

        if (paymentIntent.status === 'succeeded') {
          computedCurrentStep.value += 1
        }

        paymentDataLocal.value.amount = Math.round((checkoutPaymentDataLocal.value.amount) * 100) / 100
        paymentDataLocal.value.guest_name = checkoutPaymentDataLocal.value.guest_name
        paymentDataLocal.value.fullname = checkoutPaymentDataLocal.value.fullname
        paymentDataLocal.value.info = {
          payment_intent_id: paymentIntent.id,
          payment_method_id: paymentIntent.payment_method,
          created: paymentIntent.created,
          currency: paymentIntent.currency,
          status: paymentIntent.status,
        }

        //   let respAddPayment = null
        //   if (saveCreditCard.value === 'Y') {
        //     const body = {
        //       payment_method_id: paymentIntent.payment_method,
        //     }
        //     respAddPayment = await addPaymentMethod(body)
        //     if (respAddPayment.ok) success('Payment Method added!')
        //     else {
        //       console.error({ respAddPayment })
        //       toasdError(respAddPayment.message)
        //     }
        //   }

        //   const body = {
        //     payment_intent_id: paymentIntent.id,
        //     payment_method_id: 0,
        //     stripe_payment_method_id: saveCreditCard.value === 'Y' ? respAddPayment.data.payment_method_id : 'null',
        //   }
        //   await orderVideo(body)
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        console.error({ catchError: e })
        toasdError(e.message)
      }
    }

    const initStripe = async () => {
      try {
        // const publishableKey = 'pk_test_51JqscYIMzsnrIuPMwlmR6naniNprHRxsIeYJ8lS1sZEY8XDK4cSuH2pbpxRuSK6CiByrLBaEsGxCsg0TWnglAUBV00pCqCHvtg'
        const publishableKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY
        stripe = await loadStripe(publishableKey)

        const amount = 1000
        const appearance = {
          theme: 'night',
          variables: {
            fontFamily: 'Inter, sans-serif, system-ui',
            fontWeightNormal: '500',
            borderRadius: '8px',
            colorBackground: '#0A2540',
            colorPrimary: '#EFC078',
            accessibleColorOnColorPrimary: '#1A1B25',
            colorText: 'white',
            colorTextSecondary: 'white',
            colorTextPlaceholder: '#727F96',
            tabIconColor: 'white',
            logoColor: 'dark',
          },
          rules: {
            '.Input, .Block': {
              backgroundColor: 'transparent',
              border: '1px solid var(primary-shade)',
            },
          },
        }
        elements = stripe.elements({
          mode: 'payment',
          currency: 'usd',
          amount,
          appearance,
        })
        const paymentElement = elements.create('payment')
        paymentElement.mount('#payment-element')
        const linkAuthenticationElement = elements.create('linkAuthentication')
        linkAuthenticationElement.mount('#link-authentication-element')
        isLoading.value = false
        isInitialized.value = true
      } catch (e) {
        console.error({ e })
        toasdError(e.message)
      }
    }

    const onReadCard = async () => {
      const resp = await initiatePaymentTerminal(1)
      initiatePay.value = resp.data
      if (initiatePay.value.status === 'online'
        && !initiatePay.value.action.failure_code
        && initiatePay.value.action.status === 'in_progress'
      ) {
        showReadCard.value = false
        showSimPay.value = true
      }
    }

    const onCapture = async id => {
      showSimPay.value = false
      showReadCard.value = true
      await capturePaymentTerminal(id)
      nextStep()
    }

    const onSimCard = async () => {
      const resp = await SimCardPaymentTerminal()
      simPay.value = resp.data
      if (simPay.value.status === 'online'
        && !simPay.value.action.failure_code
        && simPay.value.action.status === 'succeeded'
      ) {
        await onCapture(initiatePay.value.action.process_payment_intent.payment_intent)
      }
    }

    const fetchOSPMethods = async () => {
      const resp = await getOnSitePaymentMethodsActiveByFacility(configFacility.value)
      OSPMethodsOptions.value = resp.data
    }

    const onPay = async () => {
      if (selectedPaymentMethod.value === 0) {
        paymentDataLocal.value.payment_method_id = PAYMENTS[0].id
      }

      if (selectedPaymentMethod.value === 1) {
        paymentDataLocal.value.payment_method_id = paymentsOptions.value[2].id
        paymentDataLocal.value.amount = Math.round((checkoutPaymentDataLocal.value.amount) * 100) / 100
        paymentDataLocal.value.info = {
          invoce_amount: Math.round((checkoutPaymentDataLocal.value.amount) * 100) / 100,
          client_amount: cardFormData.value.cash_amount,
          change: computedCashChange.value,
        }
      }

      if (selectedPaymentMethod.value === 2) {
        paymentDataLocal.value.payment_method_id = PAYMENTS[1].id
        await formSubmitStripe()
      }

      if (selectedPaymentMethod.value === 3) {
        paymentDataLocal.value.payment_method_id = OSPMethodsSelected.value
        paymentDataLocal.value.info = {
          payment_method_name: OSPMethodsOptions.value.find(e => e.id === OSPMethodsSelected.value).name,
          observation: cardFormData.value.note,
        }
      }

      emit('pay', false, isDeclined.value)
    }

    const onAddCash = cash => {
      cardFormData.value.cash_amount = Math.round((cardFormData.value.cash_amount + cash) * 100) / 100
    }

    watch([computedCurrentStep], updateCartData)

    watch([selectedPaymentMethod], async () => {
      if (!isInitialized.value && selectedPaymentMethod.value === 2) await initStripe()
    })

    onMounted(async () => {
      await fetchOSPMethods()
    })

    return {
      isLoading,
      showReadCard,
      showSimPay,
      form,
      isDeclined,
      selectedPaymentMethod,
      cardFormData,
      giftCardFormData,
      checkoutPaymentDataLocal,
      computedSubtotal,
      computedTax,
      computedAmount,
      OSPMethodsSelected,
      OSPMethodsOptions,
      cashDigitsOptions,
      paymentsOptions,

      computedCurrentStep,
      computedCashChange,

      onReadCard,
      onSimCard,
      onCapture,
      onPay,
      onAddCash,

      formSubmitStripe,
      updateCartData,
      nextStep,

      formatCurrency,
      onlyNumberDot,

      validations: {
        required,
        minNumber,
        maxNumber,
        charUnique,
        regexValidator,
      },

      t,

      icons: {
        mdiClose,
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.border-dark {
  border: 1px solid rgba(255, 255, 255, 0.22);
}

.border-ligth {
  border: 1px solid rgba(58, 53, 65, 0.14);
}

.text-green {
  color: #56CA00;
  caret-color: #56CA00;
}

.text-red {
  color: #FF4C51;
  caret-color: #FF4C51;
}

.text-cash-amount {
  font-size: 22px;
}

@include theme(text-cash-amount) using ($material) {
  .v-input__control {
    .v-input__slot {
      font-size: 24px;
      .v-text-field__slot {
        font-size: 23px;
        input {
          caret-color: #FF4C51 !important;
        }
      }
    }
  }
}
</style>
