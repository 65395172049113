<template>
  <v-dialog
    v-model="isModalOpen"
    max-width="1100px"
    min-height="500"
    persistent
    overlay-opacity="0.9"
  >
    <v-card
      class="pa-3 relative-card"
      rounded="10"
      style="width: 100%"
    >
      <v-form
        ref="form"
        @submit.prevent="onSave"
      >
        <div>
          <v-card-text class="pa-0 mt-5 mb-10 justify-center">
            <v-row class="justify-center align-center px-5">
              <!-- <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="customCost"
                  class="text-h6"
                  outlined
                  prefix="$"
                  hide-details
                  :rules="[regexValidator(customCost, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
                  @input="e => {
                    if (!charUnique(e, '.')) {
                      customCost = null
                    }
                  }"
                  @keypress="onlyNumberDot"
                />
              </v-col> -->

              <v-col
                cols="12"
                md="8"
              >
                <span class="text-h5 success--text"> {{ nameSelected }}</span>
              </v-col>

              <v-col
                cols="12"
                md="2"
                class="ml-auto"
              >
                <v-btn
                  class="text-h6"
                  color="secondary"
                  dark
                  block
                  type="submit"
                  @click="onAdd"
                >
                  Ok
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="2"
                class="ml-auto"
              >
                <v-btn
                  class="text-h6"
                  color="secondary"
                  dark
                  block
                  type="submit"
                  @click="$emit('update:is-modal-open', false)"
                >
                  {{ t('tooltip.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center">
            <v-row
              v-for="(playerItem, i) in playerItems"
              :key="playerItem.id"
              align="center"
              class="text-h6 px-5"
            >
              <v-col>
                {{ `(${playerItem.quantity}) ${playerItem.name}` }}
              </v-col>
              <v-col>
                {{ `${ formatCurrency(playerItem.amount_paid) } de ${formatCurrency(playerItem.amount)}` }}
              </v-col>
              <v-col>
                <v-text-field
                  v-model="playerItem.amount_suggested"
                  label="Amount Suggested"
                  class="text-h6"
                  prefix="$"
                  outlined
                  type="number"
                  hide-details
                  :rules="[required]"
                  :append-outer-icon="i !== 0 ? icons.mdiClose : null"
                  @click:append-outer="() => playerItem.amount_suggested = 0"
                />
              </v-col>

              <!-- <v-col
                cols="12"
                md="auto"
              >
                <v-btn
                  class="ma-0 mr-2 text-h6"
                  color="secondary"
                >
                  $5
                </v-btn>

                <v-btn
                  class="ma-0 mr-2 text-h6"
                  color="secondary"
                >
                  $10
                </v-btn>

                <v-btn
                  class="ma-0 text-h6"
                  color="secondary"
                >
                  $15
                </v-btn>
              </v-col> -->
            </v-row>
          </v-card-text>

          <v-card-text
            v-if="!computedAddClientData.reservation"
            class="pa-0 mt-5 mb-7 justify-center d-flex flex-column"
          >
            <v-row class="justify-center align-center">
              <v-col
                cols="12"
              >
                <v-expansion-panels
                  v-model="openedPanels"
                  multiple
                >
                  <v-expansion-panel
                    v-for="(reservation, i) in computedFields"
                    :key="`${reservation.field_id}-${i}`"
                  >
                    <v-expansion-panel-header
                      class="text-h5 success--text"
                    >
                      <span>
                        {{ reservation.reservation_name }}
                      </span>
                      <v-btn
                        class="mr-12"
                        style="flex: 0.25 1 auto !important;"
                        color="secondary"
                        outlined
                        @click.stop="chargeHere(reservation)"
                      >
                        Charge here
                      </v-btn>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-subheader>
                        <span
                          class="title-wrapper mb-2"
                        >
                          <span>Add Guest Player</span>
                        </span>
                      </v-subheader>
                      <v-row
                        align="center"
                      >
                        <v-col
                          cols="12"
                          md="4"
                          class="pa-2"
                        >
                          <v-text-field
                            v-model="guestName"
                            label="Guest Name"
                            class="text-h6"
                            outlined
                            hide-details
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="auto"
                          class="pa-2"
                        >
                          <v-btn
                            class="ma-0 text-h6 text-capitalize"
                            color="secondary"
                            outlined
                            block
                            @click="onAddGuest(reservation)"
                          >
                            Add guest
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-subheader>
                        <span
                          class="title-wrapper mt-4 mb-2"
                        >
                          <span>Players Joined</span>
                        </span>
                      </v-subheader>
                      <v-row>
                        <v-col
                          v-for="(player, index) of reservation.players"
                          :key="`${player.fullname}-${index}`"
                          md="4"
                          class="pa-2 cursor-pointer"
                          @click="onSelectPlayer(player, reservation)"
                        >
                          <v-card
                            :class="{'border-dark': $vuetify.theme.dark, 'border-ligth': !$vuetify.theme.dark, 'player-active': player.isActive}"
                          >
                            <v-card-text class="d-flex align-center justify-space-between pa-3">
                              <div class="d-flex align-center">
                                <v-avatar
                                  color="primary"
                                  :class="{'v-avatar-light-bg primary--text': player.avatar}"
                                  class="mr-2"
                                  size="32"
                                >
                                  <v-img
                                    v-if="player.avatar"
                                    :src="player.avatar"
                                  ></v-img>
                                  <span
                                    v-else
                                    class="font-weight-medium"
                                  >{{ avatarText(player.fullname) }}</span>
                                </v-avatar>
                                <span class="text-h6">
                                  {{ player.fullname }}
                                </span>

                                <span
                                  class="ml-2"
                                  :class="{'warning--text': player.guest, 'info--text': !player.guest }"
                                >
                                  {{ player.guest ? 'guest' : 'player' }}
                                </span>

                                <span
                                  class="ml-2 success--text"
                                >
                                  {{ player.paid === 'Y' ? 'paid' : '' }}
                                </span>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
import { ref, computed, watch } from '@vue/composition-api'
import { mdiPlus, mdiClose } from '@mdi/js'
import {
  required,
  regexValidator,
  charUnique,
  maxNumber,
  minNumber,
} from '@core/utils/validation'
import { formatCurrency, isEmpty } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { onlyNumberDot } from '@core/utils/functionsHelpers'
import { getReservationPosAmount } from '@api'

export default {
  name: 'AddClient',
  model: {
    prop: 'isModalOpen',
    event: 'update:is-modal-open',
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    addClientData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const form = ref(null)
    const client = ref(null)
    const selectedField = ref(null)
    const openedPanels = ref([])
    const customCost = ref(null)
    const guestName = ref(null)
    const reservationSelected = ref(null)
    const playerSelected = ref(null)
    const nameSelected = ref(null)
    const addData = ref({
      username: null,
      fullname: null,
      reservation_id: null,
      reservation_name: null,
    })
    const playerItems = ref([])
    const computedPlayerItems = computed(() => props.playerItems)
    const computedAddClientData = computed(() => props.addClientData)

    const computedIsModalOpen = computed(() => props.isModalOpen)
    const computedFields = computed(() => props.fields)

    const onSave = () => {
      if (form.value.validate()) {
        emit('update:is-modal-open', false)
      }
    }

    const onAdd = () => {
      if (playerSelected.value && reservationSelected.value) {
        const addClient = {
          username: playerSelected.value.username,
          fullname: playerSelected.value.fullname,
          reservation_id: reservationSelected.value.reservation_id,
          reservation_name: reservationSelected.value.reservation_name,
        }
        const products = playerItems.value.filter(e => !!e.amount_suggested && e.amount_suggested > 0)
        let mapItems = []

        if (products.length) {
          mapItems = products.map(e => {
            return {
              id: e.id,
              name: e.name,
              description: null,
              status_str: null,
              status: null,
              image: null,
              visibility: 'B',
              editable: false,

              unit_price: 0,
              tax_percent: 0,

              quantity: 0,
              subtotal: parseFloat(e.amount_suggested),
              discount: 0,
              tax: 0,
              amount: parseFloat(e.amount_suggested),

              amount_paid: parseFloat(e.amount_paid),
              amount_suggested: parseFloat(e.amount_suggested),
            }
          })
        }
        emit('addClient', addClient, mapItems)
      }
    }

    const onAddGuest = reservation => {
      if (reservation) {
        if (guestName.value) {
          emit('addGuest', {
            name: guestName.value,
            phone: null,
            match: reservation.match_id,
          })

          guestName.value = null
        }
      }
    }

    const inactivePlayers = () => {
      computedFields.value.forEach(reservation => {
        reservation.players.forEach(player => {
          player.isActive = false
        })
      })
    }

    const onSelectPlayer = async (player, reservation) => {
      try {
        inactivePlayers()
        player.isActive = true
        const resp = await getReservationPosAmount(reservation.reservation_id)
        playerItems.value = resp.data

        // customCost.value = resp.data
        reservationSelected.value = reservation
        playerSelected.value = player
        nameSelected.value = player.fullname
        openedPanels.value = []
      } catch (e) {
        console.error(e)
      }
    }

    const chargeHere = reservation => {
      const addClient = {
        username: null,
        fullname: null,
        reservation_id: reservation.reservation_id,
        reservation_name: reservation.reservation_name,
      }
      emit('addReservation', addClient)
      inactivePlayers()
    }

    const validateAmountSuggested = (value, item) => {
      if (isEmpty(value)) {
        return true
      }

      if (typeof value !== 'number' || !Number.isNaN(value)) return 'This field must be an number'

      const diff = item.amount - item.amount_paid
      if (value > diff) return `Amount must be less than $${diff}`

      return true
    }

    watch([computedIsModalOpen], () => {
      if (computedIsModalOpen.value) {
        if (computedAddClientData.value.reservation) {
          const findedReservation = computedFields.value.find(e => e.reservation_id === computedAddClientData.value.reservation_id)
          const findedPlayer = findedReservation.players.find(e => e.username === computedAddClientData.value.username)
          if (findedPlayer && findedReservation) onSelectPlayer(findedPlayer, findedReservation)
        }
        setTimeout(() => {
          // openedPanels.value = computedFields.value.map((e, i) => i)
        }, 200)
      } else {
        openedPanels.value = []
        playerItems.value = []
        customCost.value = null
        reservationSelected.value = null
        playerSelected.value = null
        nameSelected.value = null
        form.value.reset()
      }
    })

    return {
      // data
      form,
      client,
      selectedField,
      openedPanels,
      customCost,
      guestName,
      reservationSelected,
      playerSelected,
      nameSelected,
      addData,
      playerItems,

      // computed
      computedIsModalOpen,
      computedFields,
      computedPlayerItems,
      computedAddClientData,

      // methods
      onSave,
      onAddGuest,
      onSelectPlayer,
      onAdd,
      chargeHere,
      inactivePlayers,
      validateAmountSuggested,

      required,
      regexValidator,
      charUnique,
      avatarText,
      onlyNumberDot,
      formatCurrency,
      maxNumber,
      minNumber,

      // i18n
      t,

      // icons
      icons: {
        mdiPlus,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .border-dark {
    border: 1px solid rgba(255, 255, 255, 0.22);
  }

  .border-ligth {
    border: 1px solid rgba(58, 53, 65, 0.14);
  }

.absolute-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #222222;
  color: #ffffff6b;
  border-radius: 50px !important;
  padding: 2px;
  cursor: pointer;
  z-index: 1;
}
.relative-card {
  position: relative;
}

.player-active {
    border: solid 1px #FF3232 !important;
  }
</style>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

@include theme(v-subheader) using ($material) {
  white-space: nowrap;
  position: relative;

  .v-icon {
    color: map-deep-get($material, 'dividers');
  }
  .title-wrapper {
    &:not(.no-style) {
      width: 100%;
      // text-align: center;
      position: absolute;
      left: 0;
      border-bottom: 1px solid map-deep-get($material, 'dividers');
      line-height: 0.1em;
      // margin: 10px 0 20px;
    }
  }

  .title-wrapper span {
    background: map-deep-get($material, 'background');
    padding: 0 10px;
    font-size: 0.75rem;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }
}
</style>
