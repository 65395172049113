<template>
  <section class="text-base">
    <v-row>
      <v-col
        cols="12"
        md="7"
        class="justify-center"
      >
        <div class="text-center">
          <h5 class="text-h5 mb-6">
            {{ t('checkout.thank_you') }}
          </h5>
          <p class="text-bory-1">
            {{ t('checkout.we_sent_an_email') }} <span class="font-weight-medium text-high-emphasis">john.doe@example.com</span> {{ t('checkout.with_your_invoice') }}
          </p>

          <v-text-field
            v-model="email"
            :label="t('users.email')"
            class="text-h6"
            outlined
            :rules="[required, emailValidator]"
          />
        </div>

        <v-btn
          class="mt-2 text-h6"
          height="46px !important"
          color="secondary"
          block
          @click="$emit('finish')"
        >
          {{ t('checkout.finish') }}
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="5"
      >
        <card-detail
          :checkout-data="checkoutConfirmationDataLocal"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
/* eslint-disable no-return-assign */
import { ref, computed } from '@vue/composition-api'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { formatCurrency } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import { required, emailValidator } from '@core/utils/validation'

import CardDetail from '@/views/checkout/components/CartDetail.vue'

export default {
  components: {
    CardDetail,
  },
  props: {
    currentStep: {
      type: Number,
      default: 0,
      required: false,
    },
    checkoutData: {
      type: null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const email = ref(null)

    const checkoutConfirmationDataLocal = computed({
      get: () => props.checkoutData,
      set: val => {
        emit('update:checkout-data', val)
      },
    })

    const totalCost = computed(() => checkoutConfirmationDataLocal.value.total = checkoutConfirmationDataLocal.value.products.reduce((acc, item) => acc + item.price * (item.quantity || 1), 0))

    const selectedDeliveryAddress = computed(() => props.checkoutData.addresses.filter(address => address.value === props.checkoutData.deliveryAddress))

    const resolveDeliveryMethod = computed(() => {
      if (props.checkoutData.deliverySpeed === 'overnight') {
        return {
          method: 'Overnight Delivery',
          desc: 'In 1 business day.',
        }
      }

      if (props.checkoutData.deliverySpeed === 'express') {
        return {
          method: 'Express Delivery',
          desc: 'Normally in 3-4 business days',
        }
      }

      return {
        method: 'Standard Delivery',
        desc: 'Normally in 1 Week',
      }
    })

    return {
      email,
      checkoutConfirmationDataLocal,
      selectedDeliveryAddress,
      resolveDeliveryMethod,
      totalCost,

      formatCurrency,
      t,

      // validations
      required,
      emailValidator,

      icons: {
        mdiClose,
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.border-dark {
  border: 1px solid rgba(255, 255, 255, 0.22);
}

.border-ligth {
  border: 1px solid rgba(58, 53, 65, 0.14);
}
</style>
